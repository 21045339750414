.title,
.cont1 .title {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  width: 100vw;
}

.cont1 {
  display: flex;
  padding: 18px 8px 10px;
  border-radius: 6px;
}
.warp {
  display: flex;
  width: 45vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 7px;
}

.dxg {
  font-size: 14px;
  font-weight: bold;
}

.dxg::before {
  content: "";
  position: absolute;
  transform: translateY(1000%);
  height: 2px;
  width: 30px;
  background-color: #f75d36;
}
.shu {
  background: #999999;
  /* margin-bottom: 5px; */
  width: 1px;
  height: 12px;
}

.weiht {
  font-size: 10px;
}

.cont1 > div {
  flex-grow: 1;
}

.cont1 > div p:nth-of-type(1) {
  font-size: 14px;
  font-weight: bold;
  position: relative;
}

.cont1 > div p:nth-of-type(2) {
  margin-top: 5px;
  font-size: 10px;
  font-weight: bold;
}

.cont1 > div p:nth-of-type(3) {
  margin-top: 10px;
  font-size: 12px;
  width: 40vw;
}

.cont1 > div p:nth-of-type(1) + p {
  padding: 10px 10px 10px 0;
  font-size: 10px;
}
.cont1 img {
  width: 48vw;
}

@media screen and (max-width: 320px) {
  .cont1 img {
    margin-top: 8vw;
  }
  .cont2 > div > div > p:nth-of-type(1) {
    font-size: 14px;
  }

  .divs2 {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}
@media screen and (max-width: 375px) {
  .line {
    font-size: 8px;
  }
  .cont1 img {
    margin-top: 6vw;
  }
}

@media screen and (min-width: 414px) {
  .line {
    font-size: 8px;
  }
}

.cont2,
.cont2 div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.cont2 > div > div > div > p:nth-of-type(1) {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #f75d36;
  padding: 3px 0 5px 0;
  margin-top: 6px;
  width: 300px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}
.divs {
  width: 7% !important;
  height: 1px;
  background-color: #f75d36;
  position: absolute;
}
.divs:nth-child(1) {
  left: 12%;
  transform: translateY(10px);
}
.divs:nth-of-type(2) {
  right: 12%;
  transform: translateY(10px);
}

.cont2 > div > div > div > p:nth-of-type(2) {
  font-size: 9px;
  color: #666666;
  margin: 10px 20px;
  width: 80%;
}
.pdiv {
  background-color: #f7f9fc;
  width: 90%;
}
.pdiv > p {
  color: "#666666";
  font-size: 12px;
  width: 90%;
  margin-bottom: 20px;
}
.fd > p {
  color: #222222;
  font-weight: 300;
  width: 90vw;
  font-size: 10px;
}
.bak {
  margin: 10px 0 15px;
}
.bak img {
  width: 35vw;
}
.fs {
  background-color: #f7f9fc;
}

.fd > div {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 0px 10px;
}

.fd span {
  position: relative;
  z-index: 2;
  padding: 2px 0;
}

.fd img {
  vertical-align: middle;
}

.fd div > p:nth-of-type(1) {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 8px;
}

.fd div > p:nth-of-type(2) {
  color: #666666;
  text-align: center;
  font-size: 10px;
}

.fd > p {
  font-size: 10px;
  margin-bottom: 10px;
}

.cir {
  position: relative;
  width: 30px !important;
  min-width: 30px;
  height: 30px;
}

.cir::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f1f1f1;
  border-radius: 30px 0 0 30px;
  height: 100%;
  width: 50%;
}

.cir::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f75d36;
  border-radius: 0 30px 30px 0;
  height: 100%;
  width: 50%;
}

.fs div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.fs > div:nth-of-type(1) {
  width: 100% !important;
  position: absolute;
  margin-top: 10px;
}

.fs p {
  width: 50%;
  text-align: center;
  font-size: 10px;
}

.iblk {
  flex-direction: column !important;
  color: #666666;
}

.iblk span {
  width: 180px;
}

.iblk + p {
  flex-grow: 1;
  color: #666666;
  text-align: right;
}
.cont3 {
  margin-top: 15px;
}
.cont3 > div:nth-of-type(1) {
  background-color: #f7f9fc;
  padding: 10px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.cont3 > div:nth-of-type(1) p {
  color: #f75d36;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
}

.cont3 > div:nth-of-type(1) p::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -24px;
  width: 20px;
  height: 1px;
  background-color: #f75d36;
}

.cont3 > div:nth-of-type(1) p::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -24px;
  width: 20px;
  height: 1px;
  background-color: #f75d36;
}

.cont3 > div:nth-of-type(1) span {
  text-align: center;
  color: #666666;
  font-size: 10px;
}

.cont3 > p:nth-of-type(1) {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
}

.cont3 > p:nth-of-type(2) {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
  padding: 10px;
}

.cont3 > img {
  margin-bottom: 10px;
}

.cont3 > p:last-child {
  color: #f75d36;
  font-size: 14px;
  font-weight: 600;
  padding: 0 20px;
}

.cont3 .linediv {
  text-align: center;
  margin-bottom: 10px;
}
.xgfwlc {
  margin-top: 20px;
  color: #222222;
  font-size: 16px;
  background-color: #f7f9fc;
  padding: 15px 0 15px 20px;
  width: 90vw;
}
.xgfwlc > p {
  font-weight: bold;
  font-size: 14px;
}
.xgys {
  background-color: #f7f9fc;
  margin-top: 20px;
  padding: 20px 0;
}
.xgys > p {
  text-align: center;
  font-weight: bold;
}
.xgys > div {
  display: flex;
  flex-wrap: wrap;
}
.xgys > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
}
.xgys > div > div > p {
  width: 120px;
  margin-left: 20%;
  font-size: 10px;
}
.divs2 {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
}
.xz {
  margin-top: 20px;
  position: relative;
  top: 0;
  padding: 0 10px;
}
.xz > div {
  display: flex;
  justify-content: space-around;
}
.xz > div > div {
  display: flex;
  width: 40%;
}
.xz > div > div > span {
  color: #b0c3e0;
  font-size: 18px;
  margin-top: 5px;
  margin-right: 3px;
  font-weight: bold;
}
.xz > div > div > img {
  width: 30%;
  object-fit: contain;
  position: absolute;
  top: 15px;
}

.xz > div > div > div > p:nth-of-type(1) {
  font-size: 14px;
  font-weight: bold;
}
.xz > div > div > div > p:nth-of-type(2) {
  font-size: 12px;
  color: #666666;
}
@media screen and (min-width: 400px) {
  .line {
    font-size: 9px;
    color: #666666;
  }
}
.cont1 a {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  padding: 5px 10px;
  border: none;
  background-color: #f75d36;
  box-shadow: 0px 3px 12px 0px rgba(68, 68, 68, 0.15);
  border-radius: 2px;
  font-size: 13px;
  margin-top: 5px;
}
@media only screen and (max-width: 320px) {
  .line {
    font-size: 6px;
    color: #666666;
  }
  .divs:nth-child(1) {
    left: 5%;
  }
  .divs:nth-of-type(2) {
    right: 5%;
  }
  .warp {
    width: 55vw;
  }
}

.padding10 {
  padding: 10px;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.btn a {
  background-color: #f75d36;
  color: #ffffff;
  font-size: 16px;
  padding: 6px 40px;
  box-shadow: 0px 3px 26px 1px rgba(0, 63, 153, 0.15);
  border-radius: 4px;
}
