

.top {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.top>p {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  flex: 1 1 auto;
}

.top>p span {
  font-size: 12px;
  color: #666666;
}

.top>p span:nth-child(2) {
  margin-left: 10px
}

.top>a>span {
  /* border: 1px solid #F75D36; */
  color: #F75D36;
  padding: 6px 10px;
  font-size: 14px;

}

article {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
}

article .titl {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  background-color: #F7F9FC;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin-bottom: 10px;
  width: 100vw;
  position: relative;
  left: -8px;

}

article .titl p {
  width: 90vw;
  text-align: center;
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.con{
  height: auto;
  width: 88vw;
  margin: auto;
}
.con img {
  max-width: 86vw;
}

.nav p {
  padding: 16px;
  background-color: #F7F9FC;
  margin-bottom: 10px;
  width: 70vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav span {
  color: #F75D36;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #d8d8d8;
}