.info ul {
  padding: 0 5vw;
}

.info li {
  margin-bottom: 30px;
}
.info li div {
  height: 84px;
}
.flx {
  display: flex;
  position: relative;
  margin-top: 10px;
}

.flx p:nth-child(1) {
  width: 57vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  margin-bottom: 10px;
}
.flx p:nth-child(2) {
  font-size: 13px;
  position: absolute;
  bottom: 0;
}
.flx img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  position: absolute;
  right: 5px;
}
.flx div p:last-child {
  width: 65%;
}
.flx span {
  color: #999999;
}
.flx span:nth-child(1) {
  margin-right: 10px;
}
.flx span:last-child {
  display: inline-block;
  padding-top: 1px;
}

.div_footer {
  color: #d8d8d8;
  width: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.div_footer div {
  width: auto;
  margin-right: 5px;
}

.div_footer img {
  width: 12px;
  height: 7px;
  margin-top: 2px;
}

@media screen and (min-width: 414px) {
  .flx p:nth-child(2) {
    font-size: 13px;
  }
}

@media only screen and (max-width: 300px) {
  .flx p:nth-child(2) {
    font-size: 10px;
  }
}
