html,
body,
ul,
p,
pre {
  margin: 0;
  padding: 0;
  user-select: none;
}

ul {
  list-style: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #222222;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: #333333;
  text-decoration: none;
}

.col222 {
  color: #222222;
}

.col333 {
  color: #333333;
}

.col666 {
  color: #666666;
}

.col999 {
  color: #999999;
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
  padding: 8px;
  margin: 10px;
  display: flex;
  justify-content: center;
  position: relative;
}

.title::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 50px;
  background-color: #F75D36;
}