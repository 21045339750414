.about strong {
  color: #111111;
}
.about{
  position: relative;
}

.title,.title2 {
  font-size: 16px !important;
  font-weight: 600;
  position: relative;
  margin-top: 20px;
}
.title2{
  margin-top: 40px;
}
.title3{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.bimg{
  width: 100%;
  position: absolute;
  bottom: -109px;
  z-index: -10;
  left: 0;
  right: 0;
  margin: auto;
}
.zb{
  
  line-height: 25px;
}
.title2::before{
  content: 'CONTACT US';
  position: absolute;
  top: -16px;
  left: 0;
  font-size: 12px;
}
.title::before {
  content: 'ABOUT US';
  position: absolute;
  top: -16px;
  left: 0;
  font-size: 12px;
}

.about>div {
  padding: 10px;
}

.x {
  width: 1px;
  height: 15px;
  background-color: #F75D36;
  margin: 20px 0 10px 8px;
}

.flx {
  display: flex;
}

.flx p {
  margin: 0 10px 20px 0;
  font-size: 14px;
}

.flx p span {
  margin: 10px 0;
}

.flx p strong {
  font-size: 16px;
}

.flx img {
  width: 130px;
  margin-top: 23px;
}

@media screen and (max-width: 380px) {
  .flx img {
    width: 140px;
  }
}

@media screen and (max-width: 330px) {
  .flx img {
    width: 100px;
  }
}

.flx+p {
  margin-bottom: 20px;
  font-size: 14px;
}

.pclass {
  font-size: 14px;
}

.pclass strong {
  font-size: 16px;
}

.fo {
  width: 100vw;
}

.imgleft {
  /* width:300px; */
  font-size: 14px;
  margin-top: 10px;
}

.imgleft img {
  float: right;
  width: 130px;
  margin: 5px
}