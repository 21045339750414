.himg {
  width: 100%;
}

.dxg {
  font-size: 14px;
  font-weight: bold;
}

.dxg::before {
  content: "";
  position: absolute;
  transform: translateY(1100%);
  height: 2px;
  width: 30px;
  background-color: #f75d36;
}

.img2 {
  background-color: #f7f9fc;
  padding: 20px 0;
  /* position: relative; */
  margin: 20px 0;
}
.title {
  display: flex;
  justify-content: space-around;
}
.title > span {
  color: #222222;
  font-weight: bold;
  font-size: 12px;
}
.ab {
  position: absolute;
  width: 100%;
  top: 90px;
}
.title2 {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}
.title > span:nth-of-type(2) {
  margin: 0 45px;
}
.title3 {
  text-align: center;
  padding-top: 10px;
  color: #222222;
  font-size: 11px;
  font-weight: bold;
}

.img3 {
  margin: 20px 0;
}

.title4 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.title4 > div {
  width: 60px;
  height: 2px;
  background-color: #ecb819;
}

.title4 > p {
  font-size: 12px;
  font-weight: bold;
}

.title5 {
  margin-top: 40px;
}

.bottom {
  margin-top: 150px;
  background-color: #f7f9fc;
  height: 180px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.bimg {
  margin-bottom: 70px;
}

.bottom > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom > div > p {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}
.bottom > div > div {
  color: #f75d36;
}
.bottom > div > div > span {
  color: #666666;
  font-size: 10px;
}
