.cont1 {
  background-color: #F7F9FC;
  padding: 18px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.cont1 p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.cont1 span {
  width: 50%;
  position: relative;
  margin-bottom: 50px;
  color: #F75D36;
  font-size: 15px;
}
.warp {
  display: flex;
  width: 65vw;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 7px;
}

.dxg {
  font-size: 14px;
  font-weight: bold;
}

.dxg::before {
  content: "";
  position: absolute;
  transform: translateY(1000%);
  height: 2px;
  width: 30px;
  background-color: #f75d36;
}
.cont1 span:nth-of-type(1):after,
.cont1 span:nth-of-type(2):after,
.cont1 span:nth-of-type(3):after,
.cont1 span:nth-of-type(4):after {
  content: '按国家法律法规执行，授权代征个人所得税';
  position: absolute;
  bottom: -30px;
  left: calc(50% + 10px);
  transform: translateX(-50%);
  width: 144px;
  font-size: 12px;
  color: #333333;
}

.cont1 span:nth-of-type(2):after {
  content: '银行开立专属账户，定向支付报酬费用';
}

.cont1 span:nth-of-type(3):after {
  content: '劳务合作转经营合作，开具增值税专票节税高达80%';
}

.cont1 span:nth-of-type(4):after {
  content: '收益与合作方共享，长期受益';
}

.cont1 span {
  vertical-align: middle;
}

.cont1 img {
  vertical-align: middle;
  width: 46px;
}

.cont2 {
  overflow: hidden;
}

.ls {
  width: 30%;
  min-width: 75px;
  display: flex;
  position: relative;
  margin-bottom: 20px;
}
.ls pre {
  /* position: absolute; */
  color: #666666;
  margin-top: 5px;
  font-size: 12px;
  width: 30vw;
  white-space:pre-wrap;
}

.ls p {
  width: 100px;
  color: #111111;
  font-weight: bold;
  font-size: 16px;
}

.cont2 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}

.cont2 .imgb {
  width: 20%;
  margin: 0 15px;
}

@media screen and (min-width: 340px) {
  .cont2 .imgb {
    margin: 0 20px 0 36px;
  }
}

.num {
  color: #F75D36;
  font-size: 17px;
  font-weight: bold;
  position: relative;
  top: 4px;
}

.tit_ {
  font-weight: bold;
  background-color: #F7F9FC;
  padding: 10px;
  margin: 8px 0;
  font-size: 12px;
}
.cont3{
  margin-top: 20px;
  padding: 0 10px;
}
.cont3>p:nth-of-type(1) {
  margin-bottom: 16px;
  font-size: 14px;
  position: relative;
  font-weight: bold;
  margin-left: 10px;
}

.cont3>p:nth-of-type(1)::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 28px;
  height: 1px;
  background-color: #F75D36;
}

.cont3>p:nth-of-type(2) {
  margin: 0 10px;
  font-size: 10px;
}

.dt {
  border-bottom: 1px solid #d8d8d8;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 4px 10px;
  margin-bottom: 10px;
}

.cont4 {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}

.cont4>div {
  display: flex;
  width: 50%;
  margin-bottom: 10px;
}

.cont4 img {
  vertical-align: middle;
  padding: 0px 5px;
}

.csp {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.csp p {
  font-size: 14px;
  font-weight: bold;
  color: #111111;
}

.csp span {
  font-size: 12px;
  color: #666666;
  margin-top: 2px;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.btn a {
  background-color: #F75D36;
  color: #ffffff;
  font-size: 16px;
  padding: 6px 40px;
  box-shadow: 0px 3px 26px 1px rgba(0, 63, 153, 0.15);
  border-radius: 4px;
}