.App {
  padding:0 8px;
}

main {
  min-height: calc(100vh - 136px);
}

.mask {
  margin-bottom: 10px;
}

@media screen and (min-width: 340px) {
  nav li {
    padding:0 14px;
  }
}

.active {
  font-weight: bold;
  position: relative;
  flex: 1 0 auto;
  color: white;
  padding: 0 5px;
}

.active::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
  width: 40px;
  height: 3px;
  background-color: #f75d36;
  z-index: 1000;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 10px;
}

footer div:nth-child(1) {
  width: auto;
  text-align: center;
  margin-top: 30px;
}

footer p {
  margin: 4px 0;
}

footer p:nth-child(2) {
  letter-spacing: 1.5px;
}

footer p:nth-child(3) {
  display: flex;
  align-items: center;
}

footer p:nth-child(3) img {
  margin: 0 2px 0 0;
}

footer div:nth-child(2) {
  width: 1px;
  height: 60px;
  background: #ebebeb;
  margin: 6vw;
  /* margin-top: 45px; */
}

footer div:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

footer span {
  font-size: 10px;
}
.outer{
  position:fixed;
  left: 0;
  top:0;
  background-color: white;
  z-index: 10000;
  height: 40px;
  line-height: 40px;
  padding-right: 15px;
}
.tab{
  display: flex;
  overflow-y: scroll;
  margin-left: 80px; 
  font-size: 14px;
}
.tab::-webkit-scrollbar{
  display: none;
}
.tab>li{
  flex:1 0 auto;
  margin: 0 10px;
}
.tab>li:nth-of-type(1){
 position:absolute;
 left: 0;
 box-shadow: 7px 0px 7px -9px #5E5E5E;
 padding-right: 12px;
}